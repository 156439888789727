import React from 'react'
import { RESOURCES } from '../../components/constant'
import Layout from '../../components/layout'
import PageStructure from '../../components/pagestructure'
import SEO from '../../components/seo'
const SuggestedReading = () => {
  return (
    <div>
      <Layout>
        <SEO title=" Suggested Reading" />
        <PageStructure
          title=" Suggested Reading"
          desc="TESTIG"
          mode={RESOURCES}
        />
      </Layout>
    </div>
  )
}

export default SuggestedReading
